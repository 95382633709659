import { ReactNode } from 'react';

import { SelectChangeEvent } from '@mui/material';
import { TFunction } from 'i18next';
import moment from 'moment';

import { ToastParams } from '@components/notifications/toast-notifications-provider';
import { CustomerType } from '@tools/enums/customers';

import { PossiblyUndefined } from '@tools/types';

export const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
        window.location.hostname === '[::1]' ||
        window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
        ),
);

export const convertCharsetToIso8859 = async (
    file: Blob | File,
): Promise<Blob> => {
    type CHARSET = 'iso-8859-1' | 'UTF-8' | 'UTF-16';

    const readFileAsText = (file: Blob | File, encoding: CHARSET) =>
        new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = reject;
            reader.readAsText(file, encoding);
        });

    const encoding: CHARSET = 'iso-8859-1';
    const content = await readFileAsText(file, encoding);

    return new Blob([content], { type: `text/csv;charset=${encoding}` });
};

// @ts-ignore
export const uniqueFilter = (value, index, self) =>
    self.indexOf(value) === index;

export const formatCurrency = (value: number) =>
    value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 0,
    });

export const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const defaultDateFormat = 'LL';
export const formatDate = (
    date: string,
    format: string = defaultDateFormat,
): string => {
    if (!date) {
        return '';
    }

    const momentFromDate = moment(date);

    const validDate = momentFromDate.isValid()
        ? momentFromDate
        : moment(Number(date) * 1000);

    return moment(validDate).format(format);
};

export const downloadFile = (data: string, name: string): void => {
    const link = document.createElement('a');

    link.setAttribute('href', data);
    link.setAttribute('download', name);

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
};

export const copyToClipboard = async (
    textToCopy: string,
    showToast?: (args: ToastParams) => void,
    t?: TFunction,
): Promise<boolean> => {
    try {
        await navigator.clipboard.writeText(textToCopy);

        if (showToast && t) {
            showToast({
                toastType: 'info',
                message: t('cms.content.textCopied'),
            });
        }

        return Promise.resolve(true);
    } catch (err) {
        console.error('Failed to copy text to clipboard:', err);

        return Promise.reject(false);
    }
};

export const isTheSameCustomerType = (
    customerType: CustomerType,
    customerDataType?: CustomerType,
) => customerDataType === customerType;

export const capitalize = ([first, ...rest]: string) =>
    first.toUpperCase() +
    rest.map((letter: string) => letter.toLowerCase()).join('');

export const stringify = (json: Record<string, any>) => {
    try {
        return JSON.stringify(json);
    } catch (e) {
        return '';
    }
};

export const prettifyJSON = (
    jsonStringToPrettify: PossiblyUndefined<string> = '{}',
) => {
    try {
        if (jsonStringToPrettify && jsonStringToPrettify.trim() !== '') {
            const jsonObject = JSON.parse(jsonStringToPrettify);
            return JSON.stringify(jsonObject, null, 2);
        }

        return '';
    } catch (error) {
        console.error('Error parsing JSON:', error);
        return jsonStringToPrettify ?? '';
    }
};

export const toBase64 = (value: string) => btoa(encodeURIComponent(value));

export const isNumber = (value: string | undefined) =>
    value === undefined ? false : /^\d+$/.test(value);

/**
 * NOTE: This helper compares arrays of data reliably enough
 * if it wouldn't make difference if the data we're comparing
 * is `null` or `undefined`. See example below 👇👇👇
 *
 * Example edge case:
 *
 * const test1 = [{ name: "test", payload: [null, null]}]
 * const test2 = [{ name: "test", payload: [undefined, undefined]}]
 *
 * compareArrays(test1, test2) // This returns `true` as if they are equal
 *
 * ---
 *
 * However, if we change the example a bit, and have the following:
 *
 * const arr1 = [{ name: "test 1", payload: [null, null]}]
 * const arr2 = [{ name: "test 2", payload: [undefined, undefined]}]
 *
 * compareArrays(arr1, arr2) // This returns `false`
 *
 * Having this edge case in mind, consider it when using this helper 🙂
 *
 * @returns boolean
 */
export const compareArrays = (arg1: unknown[], arg2: unknown[]) => {
    // Check if both arguments are arrays and of the same length
    if (
        !Array.isArray(arg1) ||
        !Array.isArray(arg2) ||
        arg1.length !== arg2.length
    ) {
        return false;
    }

    return JSON.stringify(arg1) === JSON.stringify(arg2);
};

export const populateMultiSelectValues = (
    event: SelectChangeEvent<string>,
    setSelectedRoles: (value: string[]) => void,
    setValue: (name: string, value: string[]) => void,
) => {
    const {
        target: { value },
    } = event;
    setSelectedRoles(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
    );
    setValue('roles', typeof value === 'string' ? value.split(',') : value);
};

export const replaceHyphensWithUnderscores = (stringWithHyphens: string) =>
    stringWithHyphens.replace(/-/g, '_');

export const replaceUnderscoresWithHyphens = (stringWithUnderscores: string) =>
    stringWithUnderscores.replace(/_/g, '-');

export const sortByDate = (a: string, b: string, order = 'asc') => {
    if (order === 'asc') {
        return moment(a).isBefore(moment(b)) ? -1 : 1;
    } else {
        return moment(a).isBefore(moment(b)) ? 1 : -1;
    }
};

export const findEnumKeyByValue = <T extends Record<string, string | number>>(
    enumObject: T,
    value: string,
): keyof T | undefined => {
    for (const [key, enumValue] of Object.entries(enumObject)) {
        if (enumValue === value) {
            return key as keyof T;
        }
    }

    return undefined;
};

export const camelCaseToWords = (s: string) => {
    const result = s.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
};

export const toggleElementClass = (element: HTMLElement, className: string) => {
    const elementClasses = element.classList;
    elementClasses.toggle(className);
};

export const handleResizeClassRemoval = (
    element: HTMLElement,
    breakpoint: number,
    className: string,
) => {
    if (!element) return; // Ensure the element exists
    if (window.innerWidth >= breakpoint) {
        element.classList.remove(className);
    }
};

type MenuItem = {
    id: string;
    title: string;
    icon: ReactNode;
    link: string;
    label?: ReactNode;
    isHidden?: boolean;
};

type MenuItemGroup = {
    heading?: string;
    items: MenuItem[];
    isHidden?: boolean;
};

export const getActiveItemFromSearch = (
    menuItemGroups: MenuItemGroup[],
    search: string,
) => {
    if (!search) return null;

    const queryParams = new URLSearchParams(search); // Parse the search string
    const itemId = queryParams.get('id'); // Get the `id` value from the search query

    if (!itemId) return null;

    // Find the item based on `id`
    return (
        menuItemGroups
            .flatMap((group) => group.items)
            .find((item) => item.id === itemId)?.id ?? null
    );
};

export const getActiveItemFromPathname = (
    menuItemGroups: MenuItemGroup[],
    pathname: string,
) => {
    if (!pathname) return null;

    // Match the item based on the `link`
    return (
        menuItemGroups
            .flatMap((group) => group.items)
            .find(
                (item) =>
                    /*
                     * - **Root Path Matching:**
                     *   - The `pathname.split('/').slice(0, 3).join('/') === item.link` logic ensures the active
                     *     menu item is correctly identified by abstracting away additional path segments
                     *     introduced by redirects. This aligns the pathname to the "link" defined in
                     *     the menu mapping, ensuring consistency in active state selection.
                     */
                    pathname.split('/').slice(0, 3).join('/') === item.link,
            )?.id ?? null
    );
};

export const isSmallScreen = (): boolean => {
    const screenWidth = window.screen.width;
    const MOBILE_THRESHOLD = 900;

    return screenWidth < MOBILE_THRESHOLD;
};

interface ValidateEmailMatchArgs {
    errorMsg: string;
    emailValue: string;
    value?: string;
}

export const validateEmailMatch = ({
    errorMsg,
    emailValue,
    value = '',
}: ValidateEmailMatchArgs): string | undefined => {
    if (!emailValue) return undefined;

    if (value !== emailValue) {
        return errorMsg;
    }
    return undefined;
};
