import 'intl-pluralrules';
import { initReactI18next } from 'react-i18next';

import i18n, { ResourceKey } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import {
    brand,
    missingInterpolationHandler,
    MissingInterpolationKeys,
} from '@eon-home/react-library';

import de from '@src/assets/locales/de.json';
import en from '@src/assets/locales/en.json';
import hu from '@src/assets/locales/hu.json';
import it from '@src/assets/locales/it.json';
import pl from '@src/assets/locales/pl.json';
import translationsJson from '@src/assets/locales/translations.json';
import locales from '@src/i18n/locales.mjs';
import {
    languages,
    languageToLocale,
    localeToLanguage,
} from '@tools/enums/locales';
import {
    replaceHyphensWithUnderscores,
    replaceUnderscoresWithHyphens,
} from '@tools/utils/helpers';

import { PossiblyNull } from '@tools/types';

type Translation = Record<string, ResourceKey>;

// TODO: Add Swedish language to supported translations? 🤔🤔🤔
// We will need icon with Swedish flag for language dropdown
const translations: Translation = { en, de, it, pl, hu };

const resources = Object.keys(translations).reduce(
    (result: Record<string, Translation>, locale: string) => ({
        ...result,
        [replaceUnderscoresWithHyphens(languageToLocale[locale])]: {
            translation: translations[locale],
        },
    }),
    {},
);

const missingKeys: string[] = [];
const FALLBACK_LOCALE = 'en';

const debounce = <T extends (...args: any[]) => void>(
    func: T,
    wait: number,
): ((...args: Parameters<T>) => void) => {
    let timeout: PossiblyNull<ReturnType<typeof setTimeout>> = null;

    return (...args: Parameters<T>) => {
        const later = () => {
            timeout = null;
            func(...args);
        };

        if (timeout !== null) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(later, wait);
    };
};

const logMissingKeys = debounce(() => {
    if (missingKeys.length > 0) {
        console.error(
            `Keys without translation for ${languages[localeToLanguage[replaceHyphensWithUnderscores(i18n.language)]]}:`,
            missingKeys,
        );

        missingKeys.length = 0;
    }
    // Wait for 2 seconds of inactivity before logging so there isn't a log for every missing key
    // but log all of them at once instead
}, 2000);

const getValueByPath = <T>(obj: any, path: string): T =>
    path.split('.').reduce((acc, part) => acc?.[part], obj);

const localStorageLocaleValue = localStorage.getItem('locale');

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        fallbackLng: FALLBACK_LOCALE,
        lng: localStorageLocaleValue?.includes('-')
            ? localStorageLocaleValue
            : languageToLocale[localStorageLocaleValue!] ??
              replaceUnderscoresWithHyphens(languageToLocale[FALLBACK_LOCALE]),
        backend: {
            loadPath: '../assets/locales',
        },
        preload: locales,
        resources,
        keySeparator: '.',
        detection: {
            order: ['navigator', 'localStorage', 'htmlTag'],
            caches: ['localStorage'],
            htmlTag: document.documentElement,
            lookupLocalStorage: 'locale',
        },
        /**
         * Replace all occurrences of brand specific variables in the translations
         * Currently supported are APPNAME and BRANDNAME which get replaced with the
         * corresponding value from the brand configuration object (brand.appName and brand.brand)
         */
        missingInterpolationHandler: (
            _: string,
            regExpMatches: string[],
        ): string =>
            missingInterpolationHandler(regExpMatches, {
                [MissingInterpolationKeys.APPNAME]: brand.appName,
                [MissingInterpolationKeys.BRANDNAME]: brand.brand,
            }),
        parseMissingKeyHandler: (key: string): string => {
            if (!missingKeys.includes(key)) {
                missingKeys.push(key);
                logMissingKeys();
            }

            // Get fallback translation from i18n resources
            const fallbackLocaleKey = replaceUnderscoresWithHyphens(
                languageToLocale[FALLBACK_LOCALE],
            );
            const fallbackTranslations =
                resources?.[fallbackLocaleKey]?.translation;
            const fallbackTranslation = fallbackTranslations
                ? getValueByPath<string>(fallbackTranslations, key)
                : undefined;

            // Get translation from the default translations.json
            const defaultTranslation = getValueByPath<string>(
                translationsJson,
                key,
            );

            // If translation is found, return it
            if (defaultTranslation !== undefined) return defaultTranslation;
            if (fallbackTranslation !== undefined) return fallbackTranslation;

            // Log missing key warning
            // eslint-disable-next-line no-console
            console.warn(`Missing translation for key: "${key}"`);

            return key;
        },
    });

export default i18n;
