/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Admin Portal - API
 * # Introduction    This API provides access to admin and customer management.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssignInstallersToCompanyRequestModel,
  BadRequest,
  Conflict,
  Forbidden,
  InstallerCompaniesResponseModel,
  InstallerCompanyRequestModel,
  InstallerCompanyResponseModel,
  NotFound,
  Unauthorized,
} from '../models';

export interface InstallerCompaniesAssignInstallersToInstallerCompanyRequest {
    installerCompanyId: string;
    assignInstallersToCompanyRequestModel: AssignInstallersToCompanyRequestModel;
}

export interface InstallerCompaniesDeleteInstallerCompanyRequest {
    id: string;
}

export interface InstallerCompaniesGetInstallerCompaniesRequest {
    page?: number;
    limit?: number;
    tenantId?: string;
}

export interface InstallerCompaniesGetInstallerCompanyRequest {
    companyId: string;
}

export interface InstallerCompaniesSetInstallerCompanyRequest {
    installerCompanyRequestModel: InstallerCompanyRequestModel;
}

/**
 * 
 */
export class InstallerCompaniesApi extends runtime.BaseAPI {

    /**
     * Assign installers to installer company
     */
    async installerCompaniesAssignInstallersToInstallerCompanyRaw(requestParameters: InstallerCompaniesAssignInstallersToInstallerCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.installerCompanyId === null || requestParameters.installerCompanyId === undefined) {
            throw new runtime.RequiredError('installerCompanyId','Required parameter requestParameters.installerCompanyId was null or undefined when calling installerCompaniesAssignInstallersToInstallerCompany.');
        }

        if (requestParameters.assignInstallersToCompanyRequestModel === null || requestParameters.assignInstallersToCompanyRequestModel === undefined) {
            throw new runtime.RequiredError('assignInstallersToCompanyRequestModel','Required parameter requestParameters.assignInstallersToCompanyRequestModel was null or undefined when calling installerCompaniesAssignInstallersToInstallerCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/installer-companies/{installerCompanyId}/assignInstaller`.replace(`{${"installerCompanyId"}}`, encodeURIComponent(String(requestParameters.installerCompanyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.assignInstallersToCompanyRequestModel,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Assign installers to installer company
     */
    async installerCompaniesAssignInstallersToInstallerCompany(requestParameters: InstallerCompaniesAssignInstallersToInstallerCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.installerCompaniesAssignInstallersToInstallerCompanyRaw(requestParameters, initOverrides);
    }

    /**
     * Delete Installer Company by id
     */
    async installerCompaniesDeleteInstallerCompanyRaw(requestParameters: InstallerCompaniesDeleteInstallerCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling installerCompaniesDeleteInstallerCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/installer-companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Installer Company by id
     */
    async installerCompaniesDeleteInstallerCompany(requestParameters: InstallerCompaniesDeleteInstallerCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.installerCompaniesDeleteInstallerCompanyRaw(requestParameters, initOverrides);
    }

    /**
     * Get installer companies with pagiantion and an option for filltering
     * Get installer companies
     */
    async installerCompaniesGetInstallerCompaniesRaw(requestParameters: InstallerCompaniesGetInstallerCompaniesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InstallerCompaniesResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.tenantId !== undefined) {
            queryParameters['tenant_id'] = requestParameters.tenantId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/installer-companies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get installer companies with pagiantion and an option for filltering
     * Get installer companies
     */
    async installerCompaniesGetInstallerCompanies(requestParameters: InstallerCompaniesGetInstallerCompaniesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InstallerCompaniesResponseModel> {
        const response = await this.installerCompaniesGetInstallerCompaniesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get installer company by ID
     */
    async installerCompaniesGetInstallerCompanyRaw(requestParameters: InstallerCompaniesGetInstallerCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InstallerCompanyResponseModel>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling installerCompaniesGetInstallerCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/installer-companies/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get installer company by ID
     */
    async installerCompaniesGetInstallerCompany(requestParameters: InstallerCompaniesGetInstallerCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InstallerCompanyResponseModel> {
        const response = await this.installerCompaniesGetInstallerCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add new installer company
     */
    async installerCompaniesSetInstallerCompanyRaw(requestParameters: InstallerCompaniesSetInstallerCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InstallerCompaniesResponseModel>> {
        if (requestParameters.installerCompanyRequestModel === null || requestParameters.installerCompanyRequestModel === undefined) {
            throw new runtime.RequiredError('installerCompanyRequestModel','Required parameter requestParameters.installerCompanyRequestModel was null or undefined when calling installerCompaniesSetInstallerCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/installer-companies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.installerCompanyRequestModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Add new installer company
     */
    async installerCompaniesSetInstallerCompany(requestParameters: InstallerCompaniesSetInstallerCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InstallerCompaniesResponseModel> {
        const response = await this.installerCompaniesSetInstallerCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
