import { useMemo } from 'react';

import { Chip } from '@mui/material';

import { useAccountGetAccountUsersQuery } from '@queries/users';

import useAuthContext from '@context/auth-provider';
import { Scope } from '@tools/enums';
import { checkForScopes } from '@tools/utils';

import styles from '@components/menu/index.module.scss';

export const MenuLabelMembers = () => {
    const hasPermission = useMemo(
        () => checkForScopes([Scope.ACCOUNT_USERS_READ]),
        [],
    );

    const { isLoggedIn } = useAuthContext();
    const { data: members, isLoading: isLoadingMembers } =
        useAccountGetAccountUsersQuery(
            hasPermission,
            0,
            0,
            undefined,
            isLoggedIn,
        );

    const label = useMemo(
        () =>
            !isLoadingMembers ? (
                <Chip
                    label={members?.count}
                    size="small"
                    className={`${styles.item__chip} ${styles['item__chip--dark']}`}
                />
            ) : undefined,
        [isLoadingMembers, members?.count],
    );

    return label;
};
