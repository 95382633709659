import { useMemo } from 'react';

import { Chip } from '@mui/material';

import { useGetVendorsHealth } from '@queries/vendors';

import useAuthContext from '@context/auth-provider';
import { Scope } from '@tools/enums';
import { checkForScopes } from '@tools/utils';

import styles from '@components/menu/index.module.scss';

export const MenuLabelVendors = () => {
    const { isLoggedIn } = useAuthContext();
    const { data: vendors } = useGetVendorsHealth(isLoggedIn);

    const expiredVendorsCount = useMemo(() => {
        if (!vendors) {
            return 0;
        }

        return vendors.filter((vendor) => vendor.health !== 'valid').length;
    }, [vendors]);

    const label = useMemo(
        () =>
            checkForScopes([Scope.SYSTEM_SETTINGS_READ]) &&
            expiredVendorsCount ? (
                <Chip
                    label={expiredVendorsCount}
                    size="small"
                    color="primary"
                    className={styles.item__chip}
                />
            ) : undefined,
        [expiredVendorsCount],
    );

    return label;
};
