import { useTranslation } from 'react-i18next';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import {
    InstallerCompaniesApi,
    type UnallocatedInstallationDeviceUpdateModel,
    UnallocatedInstallationsAddGatewayDeviceRequest,
    UnallocatedInstallationsApi,
    UnallocatedInstallationsGetUnallocatedInstallationsRequest,
    UnallocatedInstallationsUpdateDeviceConfigurationRequest,
} from '@swagger-http';

import useToastList from '@components/notifications/toast-notifications-provider';
import { createRequestConfiguration } from '@tools/utils/authentication';

export const useUnallocatedInstallation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ['addUnallocatedInstallation'],
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['getUnallocatedInstallations'],
            });
        },
        mutationFn: async (
            payload: UnallocatedInstallationsAddGatewayDeviceRequest,
        ) => {
            const response = new UnallocatedInstallationsApi(
                createRequestConfiguration(),
            ).unallocatedInstallationsAddGatewayDevice(payload);

            return response;
        },
    });
};

export const useGetUnallocatedInstallations = (
    {
        page,
        limit,
        tenantId,
        installerId,
        startCode,
    }: UnallocatedInstallationsGetUnallocatedInstallationsRequest,
    enabled?: boolean,
) =>
    useQuery({
        queryKey: [
            'getUnallocatedInstallations',
            page,
            limit,
            tenantId,
            installerId,
            startCode,
        ],
        enabled,
        queryFn: async () => {
            const response = new UnallocatedInstallationsApi(
                createRequestConfiguration(),
            ).unallocatedInstallationsGetUnallocatedInstallations({
                page,
                limit,
                tenantId,
                installerId,
                startCode,
            });

            return response;
        },
    });

export const useUnallocatedInstallationDevices = (installation: string) => {
    const { t } = useTranslation();
    const { showToast } = useToastList();
    const { data } = useQuery({
        queryKey: ['getUnallocatedInstallationDevices', installation],
        queryFn: async () => {
            try {
                const api = new UnallocatedInstallationsApi(
                    createRequestConfiguration(),
                );

                const response =
                    await api.unallocatedInstallationsGetUnallocatedInstallationDevices(
                        {
                            installation,
                        },
                    );

                if (!response) {
                    showToast({
                        toastType: 'error',
                        message: t('unallocatedInstallation.requestFailed'),
                    });
                    throw new Error(`API request failed`);
                }

                return response;
            } catch (error) {
                if (error instanceof Error) {
                    showToast({
                        toastType: 'error',
                        message: t('unallocatedInstallation.fetchFailed'),
                    });
                    throw new Error(
                        `Failed to fetch installation data: ${error.message}`,
                    );
                }
                showToast({
                    toastType: 'error',
                    message: t('unallocatedInstallation.unknownError'),
                });
                throw new Error(
                    'An unknown error occurred while fetching installation data',
                );
            }
        },
    });

    return {
        data,
    };
};

export const useDeleteUnallocatedInstallation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ['deleteUnallocatedInstallation'],
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['getUnallocatedInstallations'],
            });
        },
        mutationFn: async ({
            configurationId,
        }: {
            configurationId: string;
        }) => {
            const response = await new UnallocatedInstallationsApi(
                createRequestConfiguration(),
            ).unallocatedInstallationsDeleteInstallation({
                installationId: configurationId,
            });

            return response;
        },
    });
};

export const useAssignCustomerToUnallocatedInstallation = (email: string) => {
    const queryClient = useQueryClient();
    const { showToast } = useToastList();

    return useMutation({
        mutationKey: ['assignCustomerUnallocatedInstallation'],
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['getUnallocatedInstallations'],
            });
        },
        mutationFn: async ({
            configurationId,
        }: {
            configurationId: string;
        }) => {
            try {
                const response = await new UnallocatedInstallationsApi(
                    createRequestConfiguration(),
                ).unallocatedInstallationsAssignToCustomer({
                    installationId: configurationId,
                    assignToCustomerRequestBody: {
                        customer: email,
                    },
                });

                showToast({
                    toastType: 'success',
                    message: t(
                        'unallocatedInstallation.assignCustomerDialog.feedback.notification.success',
                    ),
                });

                return response;
            } catch (error) {
                showToast({
                    toastType: 'error',
                    message: t(
                        'unallocatedInstallation.assignCustomerDialog.feedback.notification.error',
                    ),
                });
                throw error;
            }
        },
    });
};

export const useInstallerCompanies = () =>
    useQuery({
        queryKey: ['getUnallocatedInstallationCompanies'],
        queryFn: async () => {
            const response = new InstallerCompaniesApi(
                createRequestConfiguration(),
            ).installerCompaniesGetInstallerCompanies();

            return response;
        },
    });

export const useUpdateUnallocatedInstallation = (
    installationId: string,
    deviceId: string,
) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ['updateUnallocatedInstallation', installationId],
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['getUnallocatedInstallationDevices', installationId],
            });
        },
        mutationFn: async (
            unallocatedInstallationDeviceUpdateModel: UnallocatedInstallationDeviceUpdateModel,
        ) => {
            const requestParameters: UnallocatedInstallationsUpdateDeviceConfigurationRequest =
                {
                    installationId,
                    deviceId,
                    unallocatedInstallationDeviceUpdateModel,
                };

            const response = await new UnallocatedInstallationsApi(
                createRequestConfiguration(),
            ).unallocatedInstallationsUpdateDeviceConfiguration(
                requestParameters,
            );

            return response;
        },
    });
};
