import { useMemo } from 'react';

import { Chip } from '@mui/material';

import { useGetUnallocatedInstallations } from '@queries/unallocated-installations';

import styles from '@components/menu/index.module.scss';

export const MenuLabelUnallocatedInstallations = () => {
    const {
        data: unallocatedInstallations,
        isLoading,
        error,
    } = useGetUnallocatedInstallations({});

    const label = useMemo(
        () =>
            !isLoading && !error && unallocatedInstallations?.count ? (
                <Chip
                    label={unallocatedInstallations?.count}
                    size="small"
                    className={`${styles.item__chip} ${styles['item__chip--dark']}`}
                />
            ) : undefined,
        [isLoading, unallocatedInstallations?.count, error],
    );

    return label;
};
