import { useMemo } from 'react';

import { Chip } from '@mui/material';

import { useCrmCustomersGetCustomersQuery } from '@queries/customers';

import useAuthContext from '@context/auth-provider';

import styles from '@components/menu/index.module.scss';

export const MenuLabelCustomers = () => {
    const { isLoggedIn } = useAuthContext();

    const { data: customers, isLoading: isLoadingCustomers } =
        useCrmCustomersGetCustomersQuery({
            page: 0,
            limit: 1,
            order: undefined,
            filter: {},
            searchValue: '',
            enabled: isLoggedIn,
        });

    const label = useMemo(
        () =>
            !isLoadingCustomers ? (
                <Chip
                    label={customers?.count}
                    size="small"
                    className={`${styles.item__chip} ${styles['item__chip--dark']}`}
                />
            ) : undefined,
        [isLoadingCustomers, customers?.count],
    );

    return label;
};
