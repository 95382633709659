/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
// https://confluence.dev.eon.com/display/HS/feature+groups
export const enum Scope {
    DASHBOARD_READ = 'read:policy/admin/dashboard-page',

    CUSTOMERS_READ = 'read:policy/admin/customers',
    CUSTOMERS_WRITE = 'write:policy/admin/customers',
    CUSTOMERS_DELETE = 'delete:policy/admin/customers',
    CUSTOMERS_USERS_READ = 'read:policy/admin/customers/users',
    CUSTOMERS_CUSTOMERS_READ = 'read:policy/admin/customer/customers',
    CUSTOMERS_MULTIFACTOR_READ = 'read:policy/admin/customers/mfa',
    CUSTOMERS_VALIDATE_EMAIL = 'write:policy/admin/customers/validate',
    CUSTOMERS_RESET_PASSWORD = 'write:policy/admin/customers/resetpassword',

    SUBTENANT_CUSTOMERS_READ = 'read:policy/admin/customer/customers',
    SUBTENANT_CUSTOMERS_WRITE = 'write:policy/admin/customer/customers',
    SUBTENANT_DASHBOARD_READ = 'read:policy/admin/customer/customers',
    SUBTENANT_DASHBOARD_WRITE = 'write:policy/admin/customer/customers',

    CUSTOMER_SALES_READ = 'read:policy/admin/customers/sales',
    CUSTOMER_SALES_WRITE = 'write:policy/admin/customers/sales',

    CUSTOMERS_ADDRESS_WRITE = 'write:policy/admin/customers/address',

    ACCOUNT_READ = 'read:policy/admin/account',
    ACCOUNT_USERS_READ = 'read:policy/admin/account/users',
    ACCOUNT_USERS_WRITE = 'write:policy/admin/account/users',

    SIGN_OUT_READ = 'read:policy/auth/sign-out',
    SIGN_OUT_WRITE = 'write:policy/auth/sign-out',

    SYSTEM_SETTINGS_READ = 'read:policy/admin/system/settings',
    SYSTEM_SETTINGS_WRITE = 'write:policy/admin/system/settings',

    SETTINGS_VENDOR_READ = 'read:policy/admin/settings/system/vendors',
    SETTINGS_VENDOR_WRITE = 'write:policy/admin/settings/system/vendors',
    SETTINGS_SALE_TEMPLATES_READ = 'read:policy/admin/settings/sale-templates',

    NOTIFICATIONS_READ = 'read:policy/admin/settings/system/notifications',
    NOTIFICATIONS_WRITE = 'write:policy/admin/settings/system/notifications',

    ACCOUNT_API_ACCESS_READ = 'read:policy/admin/account/api-access',
    ACCOUNT_API_ACCESS_WRITE = 'write:policy/admin/account/api-access',

    SUPPORTED_FEATURES_READ = 'read:policy/admin/ftm/supported-features',
    SUPPORTED_FEATURES_WRITE = 'write:policy/admin/ftm/supported-features',
    SUPPORTED_FEATURES_DELETE = 'delete:policy/admin/ftm/supported-features',

    SUPPORTED_FEATURE_GROUP_READ = 'read:policy/admin/ftm/feature-group',
    SUPPORTED_FEATURE_GROUP_WRITE = 'write:policy/admin/ftm/feature-group',
    SUPPORTED_FEATURE_GROUP_DELETE = 'delete:policy/admin/ftm/feature-group',

    APPLICATIONS_READ = 'read:policy/admin/ftm/applications',

    PROFILE_READ = 'read:policy/profile/me',
    PROFILE_WRITE = 'write:policy/profile/me',

    PROFILE_MULTIFACTOR_READ = 'read:policy/admin/profile/multifactor',
    PROFILE_MULTIFACTOR_WRITE = 'write:policy/admin/profile/multifactor',

    MULTIFACTOR_AUTH_READ = 'read:policy/profile/sign-in/multifactor',
    MULTIFACTOR_AUTH_WRITE = 'write:policy/profile/sign-in/multifactor',

    IMPERSONATION_READ = 'read:policy/admin/customers/impersonation',
    LIVE_STREAM_READ = 'read:policy/admin/customers/sse-data',

    ACTIVITIES_READ = 'read:policy/admin/account/activities',

    CUSTOMER_TYPES_READ = 'read:policy/admin/customer-types',

    CMS_CONTENT_READ = 'read:policy/admin/cms/content',
    CMS_CONTENT_WRITE = 'write:policy/admin/cms/content',
    CMS_SETTINGS_READ = 'read:policy/admin/cms/schemas',
    CMS_SETTINGS_WRITE = 'write:policy/admin/cms/schemas',
    CMS_CLOUD_NATIVE = 'write:policy/admin/cms/cloudnative',

    WHITELABEL_CONFIGURATIONS_READ = 'read:policy/whitelabel/configurations',
    WHITELABEL_CONFIGURATIONS_WRTIE = 'write:policy/whitelabel/configurations',
    WHITELABEL_RESOURCES_READ = 'read:policy/whitelabel/resources',
    WHITELABEL_RESOURCES_WRTIE = 'write:policy/whitelabel/resources',

    BUSINESS_REPORTS_READ = 'read:policy/admin/business-reports',

    DATA_IMPORT_READ = 'read:policy/admin/import',
    DATA_IMPORT_WRITE = 'write:policy/admin/import',

    DATA_DEBUG_READ = 'read:policy/admin/energydevices/comparedata',

    MANAGER_REPORTS_READ = 'read:policy/admin/manager-report',
    MANAGER_REPORTS_WRITE = 'write:policy/admin/manager-report',

    FEATURE_FLAG_WRITE_CUSTOMERS_COUNTRY_CROATIA = 'write:feature-flag/admin/customers/country-croatia',

    UNPAIRED_DEVICES_READ = 'read:policy/admin/unallocated-installations',
    UNPAIRED_DEVICES_WRITE = 'write:policy/admin/unallocated-installations',
    INSTALLER_READ = 'read:policy/admin/installer',
    INSTALLER_WRITE = 'write:policy/admin/installer',
    FEATURE_FLAG_INSTALLER_PORTAL_READ = 'read:feature-flag/alpha/admin/installer-portal',

    INSTALLER_COMPANIES_READ = 'read:policy/admin/installer-companies',
    INSTALLER_COMPANIES_WRITE = 'write:policy/admin/installer-companies',
}

export type ScopeType = string[] | Scope[];
